<template>
    <div class="ucenter">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('PIX账号绑定')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <div class="rs-row_card">
                <div class="rs-row">
                    <div class="flex-bd">
                        <van-field
                                readonly
                                clickable
                                name="picker"
                                v-model="bank_text"
                                :label="$t('PIX类型')"
                                :placeholder="$t('请选择PIX类型')"
                                @click="showPicker = true" label-width="40%"
                        />
                    </div>
                </div>
                <div class="rs-row">
                    <div class="flex-bd">
                        <van-field v-model="number" :label="$t('PIX账号')" :placeholder="$t('请输入PIX账号')"
                                   label-width="40%"/>
                    </div>
                </div>
                <div class="rs-row">
                    <div class="flex-bd">
                        <van-field v-model="bank_branch" :label="$t('CPF/CNPJ')" :placeholder="$t('请输入CPF/CNPJ')"
                                   label-width="40%"/>
                    </div>
                </div>
                <div class="rs-row">
                    <div class="flex-bd">
                        <van-field v-model="name" :label="$t('用户名')" :placeholder="$t('请输入用户名')" label-width="40%"/>
                    </div>
                </div>
                <div class="rs-row">
                    <div class="flex-bd">
                        <van-field v-model="phone" :label="$t('电话')" :placeholder="$t('请输入电话')" label-width="40%"/>
                    </div>
                </div>
                <div class="rs-row">
                    <div class="flex-bd">
                        <van-field v-model="email" :label="$t('邮箱')" :placeholder="$t('请输入邮箱')" label-width="40%"/>
                    </div>
                </div>
            </div>
            <div class="btn-space btn-fixed">
                <van-button type="default" block @click="handleClickSave">{{$t('确认')}}</van-button>
            </div>
        </div>
        <van-popup v-model="showPicker" position="bottom" round>
            <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
                    :confirm-button-text="$t('确认')"
                    :cancel-button-text="$t('取消')"
            />
        </van-popup>
    </div>
</template>

<script>
    import helper from "../../utils/helper";

    export default {
        components: {},
        data() {
            return {
                active: 2,
                loading: false,
                showPicker: false,
                showPickerUSDT: false,
                id: 0,
                name: '',
                bank_name: '',
                bank_text: '',
                bank_branch: '',
                number: '',
                phone: '',
                email: '',
                remark: '',
                columns: ['CPF','CNPJ','EMAIL','PHONE','EVP'],
            }
        },
        methods: {
            onClickLeft() {
                this.$router.back();
            },
            onConfirm(value, index) {
                this.bank_text = value;
                this.bank_name = this.bank_text;
                this.showPicker = false;
            },
            getData() {
                this.$axios.get(this.$api_url.get_bind_bank + '?id=' + this.id).then((res) => {
                    let data = res.data;
                    if (data.code == 0) {
                        this.bank_name = data.data.info.bank_name;
                        this.bank_text = data.data.info.bank_text;
                        this.bank_branch = data.data.info.bank_branch;
                        this.name = data.data.info.name;
                        this.number = data.data.info.number;
                        this.remark = data.data.info.remark;
                        if(data.data.info.reply){
                            this.reply = JSON.parse(data.data.info.reply);
                            this.phone = this.reply.phone;
                            this.email = this.reply.email;
                        }

                        // this.columns = data.data.list;
                        this.banks = data.data.banks;
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            handleClickSave() {
                if(this.bank_name == ''){
                    this.$toast.fail(this.$t('请选择PIX类型'));
                    return;
                }
                if(this.number == ''){
                    this.$toast.fail(this.$t('请输入PIX账号'));
                    return;
                }
                if(this.bank_branch == ''){
                    this.$toast.fail(this.$t('请输入CPF/CNPJ'));
                    return;
                }
                if(this.name == ''){
                    this.$toast.fail(this.$t('请输入用户名'));
                    return;
                }
                if(this.phone == ''){
                    this.$toast.fail(this.$t('请输入电话'));
                    return;
                }
                if(this.email == ''){
                    this.$toast.fail(this.$t('请输入邮箱'));
                    return;
                }

                this.loading = true;
                let loading = this.$toast.loading();
                let _data = {};
                let url =  '/bank/save_pix';
                _data = {
                    id: this.id,
                    type: 2,
                    name: this.name,
                    bank_name: this.bank_name,
                    bank_branch: this.bank_branch,
                    number: this.number,
                    phone: this.phone,
                    email: this.email,
                    remark: this.remark,
                };
                console.log(_data);
                let data = helper.encrypt(JSON.stringify(_data));
                this.$axios
                    .post(
                        url,
                        this.$qs.stringify({
                            data: data,
                        })
                    )
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$toast.success(res.data.msg);
                            this.$router.push({path: '/bank'})
                        } else {
                            this.$toast.fail({duration: 3000, message: res.data.msg});
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                    })
                    .then(() => {
                        // loading.clear();
                        this.loading = false;
                    });
            },
        },
        mounted() {
            if (this.$route.query.id !== undefined) {
                this.id = this.$route.query.id;
            }
            this.getData();
        }

    }
</script>
<style scoped="scoped">
    .ucenter {
        background: #FFFFFF;
        min-height: 100vh;
    }
</style>
